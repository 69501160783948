import { useState } from 'react'
import Button from '../../components/Button'
import InputText from '../../components/InputText'
import Title from '../../components/Title'
import axios from '../../service/axios'

export default function NovaDisciplina(){
    const [dados, setDados] = useState({
        nome: '',
        teorica: 0,
        pratica: 0
    })

    const save = () => {
        if(dados.nome.length === 0){
            alert("Você deve informar o nome da disciplina.")
            return false
        }

        if(parseInt(dados.teorica) < 0){
            alert("Você deve informar a carga horária teórica da disciplina.")
            return false
        }

        if(parseInt(dados.pratica) < 0){
            alert("Você deve informar a carga horária prática da disciplina.")
            return false
        }

        const disciplina = {
            nome: dados.nome,
            teorica: dados.teorica,
            pratica: dados.pratica,
        }

        axios
            .post('/disciplina', disciplina)
            .then(success => alert("Dados salvos com sucesso."))
            .catch(err => alert("Erro ao salvar. Tente novamente."))
    }

    return (
        <div className='container-xl mt-5'>
            <div className='row align-items-center justify-content-around'>
                <div className='col'>
                    <Title>Cadastrar nova disciplina</Title>

                    <div className='row form-group mb-2'>
                        <div className='col'>
                            <InputText label='Nome' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    nome: event.target.value
                                })
                            }} value={dados.nome} />
                        </div>
                    </div>

                    <div className='row form-group mt-2'>
                        <div className='col'>
                            <InputText label='CH Teórica' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    teorica: event.target.value
                                })
                            }} value={dados.teorica} />
                        </div>
                        <div className='col'>
                            <InputText label='CH Prática' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    pratica: event.target.value
                                })
                            }} value={dados.pratica} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col text-center'>
                            <Button onclick={() => save()} >Salvar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}