import styles from './styles.module.css'

export default function Footer(){
    return (
        <footer className={styles.footer}>
            <div className='container-xl'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col text-center'>
                        Todos os direitos reservados.
                    </div>
                </div>
            </div>
        </footer>
    )
}