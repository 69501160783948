import { useState } from 'react'
import Title from '../../components/Title'
import InputText from '../../components/InputText'
import Button from '../../components/Button'
import axios from '../../service/axios'
import { decodeToken } from 'react-jwt'
import { useNavigate, useParams } from 'react-router-dom'

import { useData } from "../../Context/DataContext"

export default function Login(){
    const [dados, setDados] = useState({
        usuario: "",
        senha: ""
    })
    const navigate = useNavigate()
    const params = useParams()
    
    const context = useData()
    // console.log(context)

    const acessar = async () => {
        try{
            const token = await axios.post("/login", {
                user: dados.usuario,
                password: dados.senha
            })
            const usuario = decodeToken(token.data)

            console.log(usuario)
    
            context.setData({
                token: token.data,
                usuario: usuario
            })
    
            localStorage.setItem("jwt", token.data)
            localStorage.setItem("usuario", JSON.stringify(usuario))

            navigate("/"+params.url)
        }catch(err){
            alert("Dados inválidos para acessar. Tente novamente.")
        }
    }

    return (
        <div className='container-xl mt-5'>
            <div className='row align-items-center justify-content-around'>
                <div className='col-6'>
                    <Title>Login</Title>

                    <InputText 
                        label="Usuário" 
                        value={dados.usuario}
                        onchange={(event) => {
                            setDados({
                                ...dados,
                                usuario: event.target.value
                            })
                        }}
                    />
                    <InputText 
                        label="Senha" 
                        type="password" 
                        value={dados.senha} 
                        onchange={(event) => {
                            setDados({
                                ...dados,
                                senha: event.target.value
                            })
                        }}
                    />

                    <div className="text-center">
                        <Button onclick={acessar}>Acessar</Button>
                    </div>

                </div>
            </div>
        </div>
    )
}