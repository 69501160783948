import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useReactToPrint } from 'react-to-print'
import Title from '../../components/Title'
import axios from '../../service/axios'
import style from './style.module.css'
import logo from '../../assets/planoEnsinoWhiteBack.png'

export default function ImprimirPlanoEnsino(){
    const [dados, setDados] = useState({
        planoEnsino: {
            disciplina: {}
        }
    })

    const params = useParams()
    useEffect(() => {
        
        const getData = async () => {
            const planoEnsino = await axios.get('/planoEnsino/' + params.planoEnsinoId)
            const disciplina = await axios.get('/disciplina/' + planoEnsino.data.disciplinaId)

            console.log(planoEnsino.data)

            planoEnsino.data.disciplina = disciplina.data
            
            setDados({
                ...dados,
                planoEnsino: planoEnsino.data
            })
        }

        getData()
    }, [])
    
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })
    
    // console.log(dados)

    return (
        <div className='container-xl mt-5'>
            <div className='row align-items-center justify-content-around'>
                <div className='col text-center'>
                    <button onClick={handlePrint} className={style.printButton + ' btn btn-success'}>Imprimir</button>
                </div>
            </div>

            <div className='row p-5' ref={componentRef}>
                <div className='col'>
                    <div className='row text-center align-items-center'>
                        <div className='col'>
                            <img className='img-print' src={logo} />
                        </div>
                    </div>
                    <Title>{ dados.planoEnsino.disciplina.nome }</Title>

                    <div className='row'>
                        <div className='col'>
                            <div className={style.containerItem}>
                                <h5>Status: { dados.planoEnsino.aprovado ? 'Aprovado' : 'Em análise' }</h5>
                            </div>
                        </div>
                        <div className='col'>
                            <div className={style.containerItem}>
                                <h5>CH Teórica: { dados.planoEnsino.disciplina.teorica }</h5>
                            </div>
                        </div>
                        <div className='col'>
                            <div className={style.containerItem}>
                                <h5>CH Prática: { dados.planoEnsino.disciplina.pratica }</h5>
                            </div>
                        </div>
                    </div>

                    <div className={style.containerItem}>
                        <h5>Ementa:</h5>
                        <p>
                            { dados.planoEnsino.ementa }
                        </p>
                    </div>

                    <div className={style.containerItem}>
                        <h5>Conmteúdo Programático:</h5>
                        <p>
                            { dados.planoEnsino.conteudoProg }
                        </p>
                    </div>

                    <div className={style.containerItem}>
                        <h5>Bibliografia Básica:</h5>
                        <p>
                            { dados.planoEnsino.biblioBasica }
                        </p>
                    </div>

                    <div className={style.containerItem}>
                        <h5>Bibliografia Complementar:</h5>
                        <p>
                            { dados.planoEnsino.biblioComp }
                        </p>
                    </div>

                    <div className={style.containerItem}>
                        <h5>Metodologia de Avaliação:</h5>
                        <p>
                            { dados.planoEnsino.metodologia }
                        </p>
                    </div>

                    <div className={style.containerItem}>
                        <h5>Recursos Utilizados em Sala de Aula:</h5>
                        <p>
                            { dados.planoEnsino.recursos }
                        </p>
                    </div>

                    <div className={style.containerItem}>
                        <h5>Observação:</h5>
                        <p>
                            { dados.planoEnsino.observacao }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}