import { createContext, useContext, useState } from 'react'

export const DataContext = createContext({ token: '', usuario: {} })

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({
        token: '',
        usuario: {}
    })

    return (
        <DataContext.Provider value={ {data, setData} }>
            { children }
        </DataContext.Provider>
    )
}

export const useData = () => {
    const data = useContext(DataContext)
    return data
}