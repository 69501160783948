import Header from './components/Header'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Footer from './components/Footer'
import Home from './pages/Home'
import NovaDisciplina from './pages/NovaDisciplina'
import NovoUsuario from './pages/NovoUsuario'
import NovoPlanoEnsino from './pages/NovoPlanoEnsino'
import Usuarios from './pages/Usuarios'
import PlanosEnsino from './pages/PlanosEnsino'
import Disciplinas from './pages/Disciplinas'
import ImprimirPlanoEnsino from './pages/ImprimirPlanoEnsino'
import GerarPlanoEnsino from './pages/NovoPlanoEnsino/GerarPlanoEnsino'
import { DataProvider } from './Context/DataContext'
import ProtectedRoute from './pages/ProtectedRoute'
import Login from './pages/Login'

function App() {
  return (
    <DataProvider>
      <Router>
        <Header/>
          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/NovaDisciplina' exact element={<ProtectedRoute role={"admin"}><NovaDisciplina /></ProtectedRoute>} />
            <Route path='/NovoUsuario' exact element={<ProtectedRoute role={"admin"}><NovoUsuario /></ProtectedRoute>} />
            <Route path='/NovoPlanoEnsino' exact element={<ProtectedRoute role={"admin"}><NovoPlanoEnsino /></ProtectedRoute>} />
            <Route path='/Usuarios' exact element={<ProtectedRoute role={"admin"}><Usuarios /></ProtectedRoute>} />
            <Route path='/Login/:url' exact element={<Login />} />
            <Route path='/PlanosEnsino' exact element={<ProtectedRoute role={"admin"}><PlanosEnsino /></ProtectedRoute>} />
            <Route path='/Disciplinas' exact element={<ProtectedRoute role={"admin"}><Disciplinas /></ProtectedRoute>} />
            <Route path='/PlanoEnsino/imprimir/:planoEnsinoId' exact element={<ProtectedRoute role={"admin"}><ImprimirPlanoEnsino /></ProtectedRoute>} />
            <Route path='/GerarPlanoEnsino' exact element={<ProtectedRoute role={"teacher"}><GerarPlanoEnsino /></ProtectedRoute>} />
          </Routes>
        <Footer/>
      </Router>
    </DataProvider>
  )
}

export default App
