import { useEffect, useState } from 'react'
import Title from '../../components/Title'
import axios from '../../service/axios'

export default function Disciplinas(){
    const [dados, setDados] = useState({
        disciplinas: []
    })

    useEffect(() => {
        const getData = async () => {
            const disciplinas = await axios.get('/disciplina')
            
            setDados({
                ...dados,
                disciplinas: disciplinas.data.data
            })
        }

        getData()
    }, [])

    return (
        <div className='container-xl mt-5'>
            <div className='row align-items-center justify-content-around'>
                <div className='col'>
                    <Title>Cadastro de disciplinas</Title>

                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th>CH Teórica</th>
                                <th>CH Prática</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dados.disciplinas && dados.disciplinas.map(disc => (
                                    <tr key={disc.id}>
                                        <td>{ disc.id }</td>
                                        <td>{ disc.nome }</td>
                                        <td>{ disc.teorica }</td>
                                        <td>{ disc.pratica}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}