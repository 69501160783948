import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Button from '../../components/Button'
import InputText from '../../components/InputText'
import SelectBox from '../../components/SelectBox'
import TextArea from '../../components/TextArea'
import Title from '../../components/Title'
import axios from '../../service/axios'

export default function GerarPlanoEnsino(){
    const [dados, setDados] = useState({
        disciplina: '',
        disciplinaId: 0,
        ementa: '',
        conteudoProg: '',
        biblioBasica: '',
        biblioComp: '',
        metodologia: '',
        recursos: '',
        observacao: '',
        usuarioId: 0,
        ano: 2022
    })

    const [disciplinas, setDisciplinas] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const location = useLocation()

    const usuario = JSON.parse(localStorage.getItem("usuario"))


    useEffect(() => {
        const getData = async () => {
            const disciplinas = await axios.get('/disciplina')
            const usuarios = await axios.get('/usuario')

            setDisciplinas(disciplinas.data.data)
            setUsuarios(usuarios.data.data)
        }

        getData()
    }, [])

    const save = () => {
        // if(dados.ementa.length === 0){
        //     alert("O campo ementa é obrigatório.")
        //     return false
        // }
        // if(dados.conteudoProg.length === 0){
        //     alert("O campo conteúdo programático é obrigatório.")
        //     return false
        // }
        // if(dados.biblioBasica.length === 0){
        //     alert("O campo bibliografia básica é obrigatório.")
        //     return false
        // }
        // if(dados.biblioComp.length === 0){
        //     alert("O campo bibliografia complementar é obrigatório.")
        //     return false
        // }
        // if(dados.metodologia.length === 0){
        //     alert("O campo metodologia é obrigatório.")
        //     return false
        // }
        // if(dados.recursos.length === 0){
        //     alert("O campo recursos utilizados em sala de aula é obrigatório.")
        //     return false
        // }
        // if(dados.observacao.length === 0){
        //     alert("O campo observação é obrigatório.")
        //     return false
        // }

        const planoEnsino = {
            ementa: dados.ementa,
            conteudoProg: dados.conteudoProg,
            biblioBasica: dados.biblioBasica,
            biblioComp: dados.biblioComp,
            metodologia: dados.metodologia,
            recursos: dados.recursos,
            observacao: dados.observacao,
            disciplinaId: dados.disciplinaId,
            userId: dados.usuarioId,
            ano: dados.ano,
            aprovado: false
        }

        axios
            .post("http://localhost:3001/planoEnsino", planoEnsino)
            .then(success => alert("Dados salvos com sucesso"))
            .catch(err => alert("Houve um erro. Tente novamente."))
    }

    const changeUser = (event) => {
        setDados({
            ...dados,
            usuarioId: event.target.value
        })
    }

    const changeDisc = (event) => {
        setDados({
            ...dados,
            disciplinaId: event.target.value
        })
    }

    if(!usuario){
        return (<Navigate to={`/login${location.pathname}`} replace />)
    }

    return (
        <div className='container-xl mt-5'>
            <div className='row align-items-center justify-content-around'>
                <div className='col'>
                    <Title>Preencher plano de ensino</Title>

                    <div className='row form-group mb-2'>
                        <div className='col'>
                            <SelectBox
                                items={usuarios.map(item => {return { text: item.nome, value: item.id  }})}
                                label="Usuário"
                                onchange={changeUser}
                                value={usuario.id}
                                disabled={usuario ? true : false}
                            />
                        </div>
                        <div className='col'>
                            <SelectBox
                                items={disciplinas.map(item => {return { text: item.nome, value: item.id  }})}
                                label="Disciplina"
                                onchange={changeDisc}
                                value={dados.disciplinaId}
                            />
                        </div>
                    </div>

                    <div className='row form-group mb-2'>
                        <div className='col'>
                            <InputText label='Ano' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    ano: event.target.value
                                })
                            }} 
                                type="number"
                            />
                        </div>
                    </div>
                    
                    <div className='row form-group mb-2'>
                        <div className='col'>
                            <TextArea label='Ementa' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    ementa: event.target.value
                                })
                            }} />
                        </div>
                    </div>

                    <div className='row form-group mb-2'>
                        <div className='col'>
                            <TextArea label='Conteúdo programático' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    conteudoProg: event.target.value
                                })
                            }} />
                        </div>
                    </div>

                    <div className='row form-group mt-2'>
                        <div className='col'>
                            <TextArea label='Bibliografia Básica' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    biblioBasica: event.target.value
                                })
                            }} />
                        </div>
                        <div className='col'>
                            <TextArea label='Bibliografia Complementar' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    biblioComp: event.target.value
                                })
                            }} />
                        </div>
                    </div>

                    <div className='row form-group mt-2'>
                        <div className='col'>
                            <TextArea label='Metodologia de Avaliação' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    metodologia: event.target.value
                                })
                            }} />
                        </div>
                        <div className='col'>
                            <TextArea label='Recursos Utilizados em Sala de Aula' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    recursos: event.target.value
                                })
                            }} />
                        </div>
                    </div>

                    <div className='row form-group mb-2'>
                        <div className='col'>
                            <TextArea label='Observação' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    observacao: event.target.value
                                })
                            }} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col text-center'>
                            <Button onclick={save} >Salvar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}