import styles from './styles.module.css'

import logo from '../../assets/planoEnsino.png'
import { Link, useLocation } from 'react-router-dom'

export default function Header(){
    const location = useLocation()
    
    if(location.pathname.startsWith('/login')){
        return (<></>)
    }

    return (
        <header className={styles.header}>
            <div className='container-xl'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col col-sm-2'>
                        <img className='img-fluid' src={logo} alt='Meu Plano de Ensino' />
                    </div>
                    <div className='col text-center'>
                        <Link to='/'>
                            Início
                        </Link>
                    </div>
                    <div className='col text-center'>
                        <Link to='/planosEnsino'>
                            Planos de Ensino
                        </Link>
                    </div>
                    <div className='col text-center'>
                        <Link to='/usuarios'>
                            Usuários
                        </Link>
                    </div>
                    <div className='col text-center'>
                        <Link to='/disciplinas'>
                            Disciplinas
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    )
}