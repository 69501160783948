import { Navigate, useLocation } from "react-router"
import { useData } from "../../Context/DataContext"
import { decodeToken } from 'react-jwt'

export default function ProtectedRoute({ children, role }){
    
    const data = useData()
    const location = useLocation()
    // const token = JSON.parse(localStorage.getItem("jwt"))
    const token = data.data.token || localStorage.getItem("jwt")

    if(
        // !localStorage.getItem("jwt") &&
        // (!data.data.token || data.data.token === '' || data.data.token.length === 0)
        !token || token === '' || token.length === 0
    ){
        children = (<Navigate to={`/login${location.pathname}`} replace />)
    }else{
        const user = decodeToken(token)
    
        if(user.access !== 'admin' && user.access !== role){
            children = (<Navigate to={`/login${location.pathname}`} replace />)
        }
    }

    return (
        <>{ children }</>
    )
}