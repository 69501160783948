import { useState } from 'react'
import Button from '../../components/Button'
import InputText from '../../components/InputText'
import SelectBox from '../../components/SelectBox'
import Title from '../../components/Title'
import axios from '../../service/axios'

export default function NovoUsuario(){
    const [dados, setDados] = useState({
        nome: '',
        acesso: '',
        email: '',
        senha: ''
    })

    const save = () => {
        if(dados.nome.length === 0){
            alert("Você deve informar o nome do usuário")
            return false
        }
        if(dados.acesso.length === 0){
            alert("Você deve informar o tipo de acesso do usuário")
            return false
        }
        if(dados.email.length === 0){
            alert("Você deve informar o email do usuário")
            return false
        }
        if(dados.senha.length === 0){
            alert("Você deve informar a senha do usuário")
            return false
        }

        const usuario = {
            nome: dados.nome,
            acesso: dados.acesso,
            email: dados.email,
            senha: dados.senha,
        }

        axios
            .post('/usuario', usuario)
            .then(success => alert("Dados salvos com sucesso."))
            .catch(err => alert("Erro ao salvar. Tente novamente."))
    }
    
    return (
        <div className='container-xl mt-5'>
            <div className='row align-items-center justify-content-around'>
                <div className='col'>
                    <Title>Cadastrar novo usuário</Title>

                    <div className='row form-group mb-2'>
                        <div className='col'>
                            <InputText label='Nome' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    nome: event.target.value
                                })
                            }} value={dados.nome} />
                        </div>
                        <div className='col'>
                            <SelectBox
                                items={[
                                    { value: 'admin', text: 'Administrador' },
                                    { value: 'teacher', text: 'Professor' }
                                ]}
                                onchange={(event) => setDados({ ...dados, acesso: event.target.value })}
                                label='Tipo de Acesso'
                                value={dados.acesso}
                            />
                        </div>
                    </div>

                    <div className='row form-group mt-2'>
                        <div className='col'>
                            <InputText label='E-mail' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    email: event.target.value
                                })
                            }} value={dados.email} />
                        </div>
                        <div className='col'>
                            <InputText label='Senha' type='password' onchange={(event) => {
                                setDados({
                                    ...dados,
                                    senha: event.target.value
                                })
                            }} value={dados.senha} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col text-center'>
                            <Button onclick={() => save()}
                             >Salvar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}