import { useEffect, useState } from 'react'
import Title from '../../components/Title'
import axios from '../../service/axios'

export default function Usuarios(){
    const [dados, setDados] = useState({
        usuarios: []
    })

    useEffect(() => {
        const getData = async () => {
            const usuarios = await axios.get('/usuario')
            
            setDados({
                ...dados,
                usuarios: usuarios.data.data
            })
        }

        getData()
    }, [])

    return (
        <div className='container-xl mt-5'>
            <div className='row align-items-center justify-content-around'>
                <div className='col'>
                    <Title>Cadastro de usuários</Title>

                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Acesso</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dados.usuarios && dados.usuarios.map(user => (
                                    <tr key={user.id}>
                                        <td>{ user.id }</td>
                                        <td>{ user.nome }</td>
                                        <td>{ user.email }</td>
                                        <td>{ user.acesso === 'admin'? 'Administrador' : 'Professor' }</td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}