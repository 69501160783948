import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Title from '../../components/Title'
import axios from '../../service/axios'

export default function PlanosEnsino(){
    const [dados, setDados] = useState({
        planosEnsino: []
    })

    useEffect(() => {
        const getData = async () => {
            const disciplinas = await axios.get('/disciplina')
            let planosEnsino = await axios.get('/planoEnsino')

            planosEnsino = planosEnsino.data.data.map(plano => {
                plano.disciplina = (disciplinas.data.data.filter(disc => disc.id === parseInt(plano.disciplinaId)))[0]?.nome

                return plano
            })
            
            setDados({
                ...dados,
                planosEnsino: planosEnsino
            })
        }

        getData()
    }, [])

    const aprovar = (id) => {
        axios
            .patch('/planoEnsino/' + id, { aprovado: true })
            .then(success => alert('Plano de ensino parovado com sucesso.'))
            .catch(err => alert("Falha ao aprovar. tente novamente."))
    }

    const navigate = useNavigate()
    

    return (
        <div className='container-xl mt-5'>
            <div className='row align-items-center justify-content-around'>
                <div className='col'>
                    <Title>Cadastro de Planos de Ensino</Title>

                    <table className='table table-striped'>
                        <thead >
                            <tr>
                                <th>#</th>
                                <th>Plano de Ensino</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dados.planosEnsino.map(plano => (
                                    <tr key={plano.id}>
                                        <td>{ plano.id }</td>
                                        <td>{ plano.disciplina }</td>
                                        <td className='text-end'>
                                            {
                                                plano.aprovado ? (<span className='text-success'>Aprovado</span>) : (<button className='btn btn-success btn-sm' onClick={() => aprovar(plano.id)}>Aprovar</button>)
                                            }
                                            <button className='btn btn-warning btn-sm m-2 mt-0 mb-0' onClick={() => navigate(`/PlanoEnsino/imprimir/${plano.id}`)}>Visualizar</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}