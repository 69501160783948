import { useNavigate } from 'react-router'
import style from './style.module.css'

export default function Home(){
    const navigate = useNavigate()
    return (
        <div className='container-xl mt-5'>
            <div className='row'>
                <div className='col text-center'>
                    Compartilhe este link com os professores para que preencham o plano de ensino.<br />
                    https://planoensino.progm.net.br/gerarPlanoEnsino<br/><br/>
                </div>
            </div>
            <div className='row text-center align-items-center justify-content-around'>
                <div className='col'>
                    <div className={style.button} onClick={() => navigate('/novoPlanoEnsino')}>
                        <p className='p-0 m-0'>Novo Plano de Ensino</p>
                    </div>
                </div>
                <div className='col'>
                    <div className={style.button} onClick={() => navigate('/novoUsuario')}>
                        <p className='p-0 m-0'>Novo Usuário</p>
                    </div>
                </div>
                <div className='col'>
                    <div className={style.button} onClick={() => navigate('/novaDisciplina')}>
                        <p className='p-0 m-0'>Nova Disciplina</p>
                    </div>
                </div>
                <div className='col'>
                    <div className={style.button} onClick={() => navigate('/gerarPlanoEnsino')}>
                        <p className='p-0 m-0'>Gerar Plano de Ensino</p>
                    </div>
                </div>
            </div>
        </div>
    )
}