

export default function SelectBox({ items, label, onchange, value, disabled }){
    return (
        <div className='form-group'>
            <label>{ label }</label>
            <select className={' form-control'} onChange={onchange} value={value || ''} disabled={disabled}>
                <option value='' disabled>Escolha uma opção</option>
                {
                    items.map(item => (<option key={item.value} value={item.value}>{ item.text }</option>))
                }
            </select>
        </div>
    )
}